import firebase from "firebase/app";
import "firebase/storage"

export default {
  actions: {
    // eslint-disable-next-line no-unused-vars
    async fetchMedia({dispatch, commit}, {path}) {
      try {
        return await firebase.storage().ref().child(path).getDownloadURL()

      } catch (e) {
        console.log(e)
        throw e;
      }
    }
  }
}