<template>
  <header :class="{'header-m': showMenu}">
    <div
        class="h-shadow"
        :class="{'shadow-active': clicked}"
        :style="{background: (currentPage === 'About' && !showMenu) || clicked ? 'linear-gradient(180deg, #FFFFFF 24.5%, rgba(255, 255, 255, 0) 100%)'
                                                                  : 'linear-gradient(180deg, #000000 24.5%, rgba(0, 0, 0, 0) 100%)'}"
    ></div>
    <!-- #Shadow -->
    <router-link
        to="/"
        tag="a"
        exact
        class="logo"
        :class="{'visibleLogo': clicked && !showMenu}"
    >
      <!--SVG-->
      <svg class="logo-img" width="90px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 460 351.7" style="enable-background:new 0 0 460 351.7;" xml:space="preserve">
          <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#000000' : '#DADADA' }"
                d="M173.7,97.7c-1.8-3.6-4.2-6.8-7.5-9.5c-3.1-2.7-6.7-4.8-11-6.3c-4.1-1.4-8.6-2.3-13.1-2.3h-12.8c-4.5,0-9,0.7-13.1,2.3
                c-4.2,1.4-7.9,3.6-11,6.3c-3.1,2.7-5.6,6-7.5,9.5c-1.8,3.7-2.8,7.6-2.8,11.7v132.2c0,4.3,1,8.3,2.8,12c1.8,3.6,4.4,6.8,7.5,9.4
                c3.1,2.6,6.7,4.7,11,6.1c4.1,1.4,8.6,2.3,13.1,2.3h12.8c4.5,0,9-0.7,13.1-2.3c4.2-1.4,7.9-3.6,11-6.1c3.1-2.7,5.6-5.8,7.5-9.4
                c1.8-3.7,2.8-7.7,2.8-12V109.4C176.6,105.3,175.6,101.5,173.7,97.7z M124.4,109.4c0-1.1,0.4-2,1.3-2.7c1-0.9,2.2-1.3,3.8-1.3h12.8
                c1.5,0,2.8,0.4,3.8,1.3c0.8,0.7,1.3,1.6,1.3,2.7v132.2c0,1.1-0.4,2-1.3,2.7c-1.1,0.9-2.2,1.3-3.8,1.3h-12.8
                c-1.5,0-2.8-0.4-3.9-1.3c-0.8-0.7-1.3-1.6-1.3-2.7V109.4H124.4z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#000000' : '#DADADA' }"
              d="M289.4,79.7v25.8h24v166h29.1v-166H367V79.7H289.4z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#000000' : '#DADADA' }"
              d="M62.6,79.7H52.2H3.7v25.8h48.5v136.2c0,1.1-0.4,2-1.3,2.7c-1.1,0.9-2.2,1.3-3.8,1.3H34.3c-1.5,0-2.8-0.4-3.9-1.3
                c-0.8-0.7-1.3-1.6-1.3-2.7v-37.9H0v37.8c0,4.3,1,8.3,2.8,12c1.8,3.6,4.4,6.8,7.5,9.4c3.1,2.6,6.7,4.7,11,6.1
                c4.1,1.4,8.6,2.3,13.1,2.3h12.8c4.5,0,9-0.7,13.1-2.3c4.2-1.4,7.9-3.6,11-6.1c3.1-2.7,5.6-5.8,7.5-9.4c1.8-3.7,2.8-7.7,2.8-12
                V79.7H62.6z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#000000' : '#DADADA' }"
              d="M456.4,97.7c-1.8-3.6-4.2-6.8-7.4-9.5c-3.1-2.7-6.7-4.8-11-6.3c-4.1-1.4-8.6-2.3-13.1-2.3h-12.8c-4.5,0-9,0.7-13.1,2.3
                c-4.2,1.4-7.9,3.6-11,6.3c-3.1,2.7-5.6,6-7.5,9.5c-1.8,3.7-2.8,7.6-2.8,11.7v132.2c0,4.3,1,8.3,2.8,12c1.8,3.6,4.4,6.8,7.5,9.4
                c3.1,2.6,6.7,4.7,11,6.1c4.1,1.4,8.6,2.3,13.1,2.3h12.8c4.5,0,9-0.7,13.1-2.3c4.2-1.4,7.9-3.6,11-6.1c3.1-2.7,5.6-5.8,7.4-9.4
                c1.8-3.7,2.8-7.7,2.8-12V109.4C459.2,105.3,458.4,101.5,456.4,97.7z M407.1,109.4c0-1.1,0.4-2,1.3-2.7c1-0.9,2.2-1.3,3.8-1.3h12.8
                c1.5,0,2.8,0.4,3.8,1.3c0.8,0.7,1.3,1.6,1.3,2.7v132.2c0,1.1-0.4,2-1.3,2.7c-1.1,0.9-2.2,1.3-3.8,1.3h-12.8
                c-1.5,0-2.8-0.4-3.9-1.3c-0.8-0.7-1.3-1.6-1.3-2.7V109.4H407.1z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#000000' : '#DADADA' }"
              d="M248.2,79.7v92.2L191.9,0v271.4H221v-91.6l56.4,171.8v-272H248.2z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              class="st1" d="M289.4,105.4h24h29.1H367V79.7h-77.6V105.4z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              class="st1" d="M81.2,79.7H3.7v25.8h77.6V79.7z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              class="st1" d="M124.3,116c0-0.4,0-0.9,0.1-1.1v-5.4c0-1.1,0.4-2,1.3-2.7c1-0.9,2.2-1.3,3.8-1.3h5.2h7.6h33.9
                c-0.4-2.7-1.3-5.3-2.4-7.7c-1.8-3.6-4.2-6.8-7.5-9.5c-3.1-2.7-6.7-4.8-11-6.3c-4.1-1.4-8.6-2.3-13.1-2.3h-12.8
                c-4.5,0-9,0.7-13.1,2.3c-4.2,1.4-7.9,3.6-11,6.3c-3.1,2.7-5.6,6-7.5,9.5c-1.8,3.7-2.8,7.6-2.8,11.7v132.2c0,4.3,1,8.3,2.8,12
                c1.8,3.6,4.4,6.8,7.5,9.4c3.1,2.6,6.7,4.7,11,6.1c2.5,0.9,5.2,1.6,7.9,1.9V116z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              class="st1" d="M407.1,241.7V116v-6.6c0-1.1,0.4-2,1.3-2.7c1-0.9,2.2-1.3,3.8-1.3h5.3h7.4h33.9c-0.4-2.7-1.3-5.3-2.4-7.7
                c-1.8-3.6-4.2-6.8-7.4-9.5c-3.1-2.7-6.7-4.8-11-6.3c-4.1-1.4-8.6-2.3-13.1-2.3h-12.8c-4.5,0-9,0.7-13.1,2.3
                c-4.2,1.4-7.9,3.6-11,6.3c-3.1,2.7-5.6,6-7.5,9.5c-1.8,3.7-2.8,7.6-2.8,11.7v132.2c0,4.3,1,8.3,2.8,12c1.8,3.6,4.4,6.8,7.5,9.4
                c3.1,2.6,6.7,4.7,11,6.1c2.5,0.9,5.2,1.6,8,1.9V241.7z"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              class="st1" d="M277.3,351.7L191.9,91.2V0l85.5,260.5V351.7z"/>
      </svg>
      <svg class="logo-text" width="40px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 246 353" style="enable-background:new 0 0 246 353;" xml:space="preserve">
         <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
               d="m3.8,1l-3.8,0l0,351.7l3.8,0l0,-351.7z" class="st0"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m102.3,123.3l0,-41.6l-4.5,0l0,-3.3l13,0l0,3.3l-4.5,0l0,41.6l-4,0z" class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m131.8,97.2c0,1.1 -0.3,2.1 -0.8,3c-0.5,0.9 -1.2,1.6 -2,1.9l0,0.3c0.5,0.5 0.8,1 1.1,1.6c0.3,0.5 0.5,1.4 0.7,2.4c0.2,1.1 0.4,2.5 0.5,4.3c0.1,1.8 0.3,4.2 0.4,7.2c0,1.4 0.1,2.5 0.3,3.3s0.4,1.5 0.9,2.3l-4.4,0c-0.3,-0.6 -0.6,-1.3 -0.7,-2c-0.1,-0.7 -0.2,-1.8 -0.3,-3.2l-0.2,-4.4c-0.1,-2.1 -0.2,-3.8 -0.3,-5.1c-0.1,-1.3 -0.2,-2.4 -0.4,-3.1c-0.2,-0.7 -0.4,-1.2 -0.6,-1.5c-0.3,-0.3 -0.6,-0.4 -1.1,-0.4l-2.7,0l0,19.8l-4.1,0l0,-45l6.7,0c2.4,0 4.1,0.6 5.2,1.7c1.1,1.1 1.6,2.8 1.6,4.9l0,12l0.2,0zm-9.5,-15.7l0,19l2.6,0c1,0 1.7,-0.3 2.1,-0.8c0.4,-0.5 0.7,-1.4 0.7,-2.7l0,-12.1c0,-1.3 -0.2,-2.1 -0.7,-2.6c-0.4,-0.5 -1.1,-0.8 -2.1,-0.8l-2.6,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m153.1,115c0,3.3 -0.5,5.6 -1.6,6.9c-1.1,1.3 -2.8,1.9 -5.2,1.9c-1.2,0 -2.2,-0.1 -3,-0.4c-0.8,-0.3 -1.5,-0.8 -2.1,-1.4c-0.5,-0.7 -0.9,-1.6 -1.2,-2.7c-0.3,-1.1 -0.4,-2.5 -0.4,-4.2l0,-36.8l4,0l0,36.7c0,2.2 0.2,3.7 0.6,4.5s1.1,1.1 2.1,1.1c1,0 1.7,-0.4 2.1,-1.1c0.4,-0.8 0.6,-2.2 0.6,-4.5l0,-36.7l4,0l0,36.7l0.1,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m161.4,123.3l0,-45l12.3,0l0,3.1l-8.2,0l0,17.3l6.9,0l0,3l-6.9,0l0,18.5l8.3,0l0,3.1l-12.4,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m98.9,163.1c0,-3.4 0.5,-5.8 1.5,-7.2c1,-1.3 2.8,-2 5.3,-2c2.4,0 4.1,0.7 5.2,2s1.7,3.7 1.7,7.2l0,9.5l-3.9,0l0,-9.5c0,-2.3 -0.2,-3.9 -0.6,-4.7c-0.4,-0.8 -1.2,-1.2 -2.2,-1.2c-1.1,0 -1.9,0.4 -2.3,1.2c-0.4,0.8 -0.7,2.4 -0.7,4.7l0,27.5c0,1.2 0.1,2.1 0.2,2.9c0.1,0.8 0.3,1.4 0.5,1.8c0.2,0.4 0.5,0.8 0.9,0.9c0.4,0.2 0.8,0.3 1.4,0.3s1,-0.1 1.4,-0.3c0.4,-0.2 0.6,-0.5 0.9,-0.9c0.2,-0.4 0.4,-1 0.5,-1.8c0.1,-0.8 0.2,-1.7 0.2,-2.9l0,-9.5l3.9,0l0,9.5c0,3.5 -0.6,5.9 -1.7,7.2c-1.1,1.3 -2.9,2 -5.2,2c-1.2,0 -2.3,-0.2 -3.1,-0.5c-0.9,-0.3 -1.6,-0.8 -2.1,-1.5c-0.5,-0.7 -0.9,-1.7 -1.2,-2.8c-0.3,-1.2 -0.4,-2.6 -0.4,-4.3l0,-27.6l-0.2,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m134.3,173.2c0,1.1 -0.3,2.1 -0.8,3c-0.5,0.9 -1.2,1.6 -2,1.9l0,0.3c0.5,0.5 0.8,1 1.1,1.6c0.3,0.5 0.5,1.4 0.7,2.4c0.2,1.1 0.4,2.5 0.5,4.3c0.1,1.8 0.3,4.2 0.4,7.2c0,1.4 0.1,2.5 0.3,3.3s0.4,1.5 0.9,2.3l-4.4,0c-0.3,-0.6 -0.6,-1.3 -0.7,-2c-0.1,-0.7 -0.2,-1.8 -0.3,-3.2l-0.2,-4.4c-0.1,-2.1 -0.2,-3.8 -0.3,-5.1c-0.1,-1.3 -0.2,-2.4 -0.4,-3.1c-0.2,-0.7 -0.4,-1.2 -0.6,-1.5c-0.3,-0.3 -0.6,-0.4 -1.1,-0.4l-2.7,0l0,19.8l-4.1,0l0,-45l6.7,0c2.4,0 4.1,0.6 5.2,1.7c1.1,1.1 1.6,2.8 1.6,4.9l0,12l0.2,0zm-9.4,-15.7l0,19l2.6,0c1,0 1.7,-0.3 2.1,-0.8c0.4,-0.5 0.7,-1.4 0.7,-2.7l0,-12.2c0,-1.3 -0.2,-2.1 -0.7,-2.6c-0.4,-0.5 -1.1,-0.8 -2.1,-0.8l-2.6,0l0,0.1z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m142.5,199.3l0,-45l12.3,0l0,3.1l-8.3,0l0,17.3l6.9,0l0,3l-6.9,0l0,18.5l8.3,0l0,3.1l-12.3,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m171.6,190.5l-5.4,0l-1.1,8.8l-4,0l6.1,-45l3.3,0l6.3,45l-4.1,0l-1.1,-8.8zm-1.7,-12.6c-0.2,-1.6 -0.4,-3.1 -0.5,-4.8c-0.1,-1.6 -0.3,-3.1 -0.3,-4.5l-0.3,0c-0.1,1.3 -0.2,2.8 -0.4,4.5c-0.2,1.6 -0.4,3.2 -0.6,4.8l-1.3,9.3l4.5,0l-1.1,-9.3z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m184.2,199.3l0,-41.6l-4.5,0l0,-3.3l13,0l0,3.3l-4.5,0l0,41.6l-4,0z" class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m200.2,199.3l0,-45l4.1,0l0,45l-4.1,0z" class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m215.2,154.3l2.5,19.7c0.2,1.8 0.4,3.6 0.7,5.6c0.2,2 0.4,3.9 0.5,5.7l0.3,0c0.2,-1.8 0.4,-3.7 0.6,-5.7c0.2,-2 0.4,-3.9 0.6,-5.6l2.5,-19.7l4,0l-6.1,45l-3.3,0l-6.3,-45l4,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m233.6,199.3l0,-45l12.3,0l0,3.1l-8.3,0l0,17.3l6.9,0l0,3l-6.9,0l0,18.5l8.3,0l0,3.1l-12.3,0z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m99.1,275.3l0,-45l4.1,0l0,41.8l7.6,0l0,3.2l-11.7,0z" class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m127.3,266.5l-5.4,0l-1.1,8.8l-4,0l6.1,-45l3.3,0l6.3,45l-4.1,0l-1.1,-8.8zm-1.7,-12.6c-0.2,-1.6 -0.4,-3.1 -0.5,-4.8c-0.1,-1.6 -0.3,-3.1 -0.3,-4.5l-0.3,0c-0.1,1.3 -0.2,2.8 -0.4,4.5c-0.2,1.6 -0.4,3.2 -0.6,4.8l-1.3,9.3l4.5,0l-1.1,-9.3z"
              class="st1"/>
        <path :style="{ fill: (currentPage === 'About') && !showMenu ? '#404040' : '#FFFFFF' }"
              d="m139.3,275.3l0,-45l6.9,0c2.4,0 4,0.5 5,1.4c1,0.9 1.4,2.4 1.4,4.6l0,11c0,1.1 -0.2,2 -0.6,2.7c-0.4,0.7 -0.9,1.2 -1.5,1.4l0,0.3c0.8,0.3 1.5,0.7 2,1.5c0.5,0.7 0.8,2 0.8,3.7l0,12c0,2.4 -0.5,4.1 -1.5,5s-2.7,1.4 -5.1,1.4l-7.4,0zm4.1,-41.8l0,16.9l2.9,0c0.9,0 1.5,-0.2 1.9,-0.6c0.3,-0.4 0.5,-1.2 0.5,-2.4l0,-11c0,-1.2 -0.2,-2 -0.5,-2.4c-0.3,-0.4 -0.9,-0.6 -1.9,-0.6l-2.9,0l0,0.1zm0,20l0,18.8l3.3,0c1,0 1.6,-0.2 2,-0.7c0.4,-0.4 0.5,-1.4 0.5,-2.7l0,-12c0,-1.3 -0.2,-2.2 -0.5,-2.7c-0.4,-0.4 -1,-0.7 -2,-0.7l-3.3,0z"
              class="st1"/>
  </svg>
    </router-link>
    <div class="right fromTo">
      <div class="language" :class="{'language-active': showLanguages}">
        <a
            href="#"
            @click.prevent="showLanguagesHandler"
            class="languageBox"
            id="languageBox"
            :style="{ color: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000' : '#fff', 
            border: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '1px solid #00000020' : '1px solid #ffffff50' }"
        >
          {{ language.name.toUpperCase() }}
        </a>
        <!--Absolute-->
        <div
            class="language-choose"
            v-show="showLanguages"
            :style="{ backgroundColor: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#fff' : '#000', border: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '1px solid #00000020' : '1px solid #ffffff50' }"
        >
          <div class="language-block"
               v-for="(lang, i) in languages"
               :key="i"
               @click.prevent="switchLanguage(lang)"
          >
            <img
                :src="lang.flag"
                alt=""
                class="flag"
                :class="{'flag-shadow': (currentPage === 'About' && !showMenu) || (clicked && !showMenu)}"
            >
            <div class="languageBox">
              <span
                  :style="{color: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000' : '#fff'}">{{
                  lang.title.toUpperCase()
                }}</span>
            </div>
          </div>

        </div>
        <!--#Absolute-->
      </div>
      <a href="#" class="menu" @click.prevent="menuListener">
        <span
            class="menu__twix"
            :class="{'menu__twix-active-1': showMenu}"
            :style="{ backgroundColor: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000' : '#fff' }"
        ></span>
        <span
            class="menu__twix"
            :class="{'menu__twix-active-2': showMenu}"
            :style="{ backgroundColor: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000' : '#fff' }"
        ></span>
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: ['value', 'clicked'],
  data: () => ({
    showLanguages: false,
    showMenu: false,
    language: {name: '', id: ''},
    languages: []
  }),
  watch: {
    value() {
      if (!this.value) {
        this.showMenu = false
      }
    }
  },
  computed: {
    currentPage() {
      return this.$route.name;
    }
  },
  methods: {
    menuListener() {
      this.showMenu = !this.showMenu
      this.$emit('showMenu', this.showMenu)
    },
    showLanguagesHandler() {
      this.showLanguages = !this.showLanguages
    },
    switchLanguage(lang) {
      localStorage.setItem('language', JSON.stringify({name: lang.title, id: lang.id}))
      this.$router.go()
    },
    outsideClick(e, node) {
      if (!node || node.contains(e.target)) {
        return
      }
      if (this.showLanguages) this.showLanguages = false
    }
  },
  async mounted() {
    const $language = document.querySelector('.language')
    const languages = await this.$store.dispatch('fetchLanguages')

    let lLanguages = []

    let tempArr = []

    Object.keys(languages).forEach(key => {
      lLanguages.push(languages[key])
      if (languages[key].visible) {
        tempArr.push({
          title: languages[key].name,
          flag: languages[key].images.flag,
          id: key,
          pos: languages[key].pos
        })
      }
    })

    lLanguages.sort((a, b) => a.pos - b.pos)

    try {
      const localLanguage = JSON.parse(localStorage.getItem('language'))
      if (localLanguage && languages[localLanguage.id] && languages[localLanguage.id].visible) {
        this.language = localLanguage
      }
      else {
        // eslint-disable-next-line no-unused-vars
        for (let lan of lLanguages) {
          if (lan.visible) {
            this.language = {id: lan.id, name: lan.name}
            break
          }
        }
      }

    } catch (e) {
      // eslint-disable-next-line no-unused-vars
      for (const [_, value] of Object.entries(languages)) {
        if (value.visible) {
          this.language = {id: value.id, name: value.name}
          break
        }
      }
    }

    tempArr.sort((a, b) => a.pos - b.pos)

    for await (let item of tempArr) {
      const url = await this.$store.dispatch('fetchMedia', {path: item.flag})
      this.languages.push({
        title: item.title,
        flag: url,
        id: item.id
      })
    }

    document.addEventListener(`mousedown`, e => this.outsideClick(e, $language))
    document.addEventListener(`touchstart`, e => this.outsideClick(e, $language))

    // const isDevice = navigator.userAgent.match(/Android/i)
    //     || navigator.userAgent.match(/webOS/i)
    //     || navigator.userAgent.match(/iPhone/i)
    //     || navigator.userAgent.match(/iPad/i)
    //     || navigator.userAgent.match(/iPod/i)
    //     || navigator.userAgent.match(/BlackBerry/i)
    //     || navigator.userAgent.match(/Windows Phone/i)
    //     || /MacIntel/.test(navigator.platform) && window.innerWidth <= 1024
    //
    //
    // const up = isDevice ? 'touchend' : 'mouseup';
    // console.log(up)
    // document.addEventListener(up, () => {
    //   if (this.showLanguages) {
    //     if (!isDevice) {
    //       this.showLanguages = false
    //     } else {
    //       setTimeout(() => {
    //         this.showLanguages = false
    //       }, 10)
    //     }
    //   }
    // })
  }
}
</script>

<style lang="sass">
$threshold: calc(20px + 15 * ((100vw - 320px) / (1920 - 320)))
$thresholdTopBottom: calc(14px + 10 * ((100vw - 320px) / (1920 - 320)))
$height: calc(68px + (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320)))))
header
  pointer-events: none
  position: fixed
  top: 0
  left: 0
  display: flex
  width: 100%
  height: $height
  padding: $thresholdTopBottom $threshold

  z-index: 2

  a
    display: block
  //Logo
  .logo
    display: flex
    pointer-events: visible

  @media (max-width: 767px)
    .visibleLogo
      display: none

  .logo-text
    margin-left: 20px
  //Right
  .right
    position: absolute
    top: 50%
    right: $threshold
    transform: translateY(-50%)
    display: flex
    align-items: center
    z-index: 2
    //Language
    .language
      pointer-events: visible
      position: relative
      width: 45px
      height: 45px

      border-radius: 50%
      background-color: transparent
      text-align: center
      line-height: 45px
      font-family: 'Galderglynn1884SqRg', sans-serif
      font-size: 27px
      font-weight: 400
      letter-spacing: 0.02em

      &-active
        background-color: #c4c4c420

      &:hover
        background-color: #c4c4c420

      .languageBox
        width: 45px
        height: 45px
        border-radius: 50%

      .language-choose
        position: absolute
        right: 0
        top: calc(45px + 12px)
        max-height: 155px
        border-radius: 20px
        overflow: auto
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15)

        .language-block
          display: flex
          justify-content: flex-end
          align-items: center
          height: 45px
          padding-left: calc(29.44px / 2)
          cursor: pointer

          &:first-child
            padding-top: 4px

          &:last-child
            padding-bottom: 4px

          &:hover
            background-color: #c4c4c420

          .flag
            margin-right: -4px
            height: 25px
            width: 25px

          .flag-shadow
            filter: drop-shadow(0 0 6px rgba(0, 0, 0, .5))

    //Menu
    .menu
      pointer-events: visible
      height: 24px
      display: flex
      justify-content: space-around
      align-items: center
      flex-direction: column
      margin-left: 15px
      padding: 6px 0

      .menu__twix
        display: block
        width: 30px
        height: 2px
        transition: 0.8s ease

      .menu__twix-active-1
        transform: rotate(-45deg) translateY(4px)

      .menu__twix-active-2
        transform: rotate(45deg) translateY(-4px)

.visibleLogo
  display: none

//Media
@media (min-width: 768px)
  .logo-text
    display: block

@media (max-width: 767px)
  .logo-text
    display: none

@media (max-height: 385px) and (orientation: landscape)
  .header-m
    position: absolute
</style>