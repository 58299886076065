<template>
  <div class="loading-container" :style="{'background-color': $route.name === 'About' ? '#000' : 'none'}">
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 674 630.5"
         style="enable-background:new 0 0 674 630.5;"
         xml:space="preserve"
         class="loading-svg"
         id="loading-svg"
    >
  <path class="st0" d="M5.4,154.7h71.2v200c0,1.6-0.7,2.9-1.8,4.1c-1.6,1.4-3.4,1.8-5.6,1.8H50.4c-2.3,0-4.1-0.7-5.9-1.8
  c-1.4-1.1-1.8-2.3-1.8-4.1V299H0v55.4c0,6.3,1.4,12.2,4.1,17.6c2.7,5.2,6.3,10.1,11,13.7c4.5,3.8,9.9,7,16,9
  c6.1,2,12.6,3.4,19.1,3.4h18.7c6.5,0,13.3-1.1,19.1-3.4c6.1-2,11.5-5.2,16-9c4.5-4.1,8.3-8.6,11-13.7c2.7-5.4,4.1-11.3,4.1-17.6
  l0.2-199.7v-37.8H5.4V154.7z"/>
      <path class="st0" d="M673.5,154.7L673.5,154.7c-0.7-4.1-1.8-7.7-3.6-11.3c-2.7-5.2-6.3-10.1-11-14c-4.5-4.1-9.9-7.2-16-9.2
  c-6.1-2-12.6-3.4-19.1-3.4h-18.7c-6.5,0-13.3,1.1-19.1,3.4c-6.1,2-11.5,5.2-16,9.2c-4.5,4.1-8.3,8.8-11,14
  c-2.7,5.4-4.1,11-4.1,17.1v194.1c0,6.3,1.4,12.2,4.1,17.6c2.7,5.2,6.3,10.1,11,13.7c4.5,3.8,9.9,7,16,9c3.6,1.4,7.7,2.3,11.7,2.7
  c0,0,4.7,0.7,9.9,0.7h16.2c6.5,0,13.3-1.1,19.1-3.4c6.1-2,11.5-5.2,16-9c4.5-4.1,8.3-8.6,11-13.7c2.7-5.4,4.1-11.3,4.1-17.6
  V160.8C674,158,673.5,154.7,673.5,154.7z M631,354.9c0,1.6-0.7,2.9-1.8,4.1c-1.6,1.4-3.4,1.8-5.6,1.8h-18.7c-5.6,0-7.2-2-7.2-3.6
  l-0.2-186.9v-9.7c0-1.6,0.7-2.9,1.8-4.1c1.4-1.4,3.4-1.8,5.6-1.8h7.9h11c4,0,7.2,2.2,7.2,6.1C631,164.1,631,354.9,631,354.9z"/>
      <polygon class="st0" points="424.9,154.9 460.3,154.9 460.3,398.6 503.1,398.6 503.1,154.9 538.9,154.9 538.9,116.9 424.9,116.9
        "/>
      <path class="st0" d="M259.5,154.7c-0.7-4.1-1.8-7.7-3.6-11.3c-2.7-5.2-6.3-10.1-11-14c-4.5-4.1-9.9-7.2-16-9.2
  c-6.1-2-12.6-3.4-19.1-3.4h-18.7c-6.5,0-13.3,1.1-19.1,3.4c-6.1,2-11.5,5.2-16,9.2c-4.5,4.1-8.3,8.8-11,14
  c-2.7,5.4-4.1,11-4.1,17.1v194.1c0,6.3,1.4,12.2,4.1,17.6c2.7,5.2,6.3,10.1,11,13.7c4.5,3.8,9.9,7,16,9c3.6,1.4,7.7,2.3,11.7,2.7
  c0,0,4.7,0.7,9.9,0.7h16.2c6.5,0,13.3-1.1,19.1-3.4c6.1-2,11.5-5.2,16-9c4.5-4.1,8.3-8.6,11-13.7c2.7-5.4,4.1-11.3,4.1-17.6
  V160.8C260,158,259.5,154.7,259.5,154.7L259.5,154.7z M217,354.9c0,1.6-0.7,2.9-1.8,4.1c-1.6,1.4-3.4,1.8-5.6,1.8h-18.7
  c-5.6,0-7.2-2-7.2-3.6l-0.2-186.9v-9.7c0-1.6,0.7-2.9,1.8-4.1c1.4-1.4,3.4-1.8,5.6-1.8h7.9h11c4,0,7.2,2.2,7.2,6.1
  C217,164.1,217,354.9,217,354.9z"/>
      <polygon class="st0" points="364.6,252.6 281.7,0 281.7,134 281.7,398.6 324.5,398.6 324.5,264.4 407.4,517 407.4,116.9
  364.6,116.9 			"/>
      <path class="st0" d="M193,618.1c0,2.9-0.2,5-0.9,6.1s-1.6,1.6-2.9,1.6c-1.4,0-2.3-0.5-2.9-1.6c-0.7-1.1-0.9-3.2-0.9-6.1v-49.8
  h-5.4v49.8c0,2.3,0,4.1,0.5,5.6c0.2,1.4,0.9,2.7,1.6,3.6c0.9,0.9,1.8,1.6,2.9,2c1.1,0.5,2.5,0.7,4.1,0.7c3.4,0,5.6-0.7,7.2-2.5
  c1.6-1.6,2.3-4.7,2.3-9.2v-50H193V618.1z"/>
      <path class="st0" d="M299.7,622c0-4.1-0.2-7.2-0.5-9.7c-0.2-2.5-0.5-4.5-0.7-5.9c-0.2-1.6-0.5-2.7-0.9-3.4
  c-0.5-0.7-0.9-1.4-1.6-2v-0.5c1.1-0.5,2-1.1,2.7-2.5c0.7-1.1,1.1-2.5,1.1-4.1v-16.4c0-2.9-0.9-5.2-2.3-6.8
  c-1.4-1.6-3.8-2.3-7-2.3h-9.2v61h5.6v-26.8h3.6c0.5,0,0.9,0,1.4,0.5c0.5,0.5,0.7,1.1,0.9,2c0.2,1.1,0.2,2.5,0.5,4.3
  c0.2,1.8,0.2,4.1,0.5,7l0.2,6.1c0.2,1.8,0.2,3.4,0.5,4.3c0.2,0.9,0.5,1.8,0.9,2.7h6.1c-0.7-1.1-1.1-2-1.4-3.2
  C299.9,625.3,299.7,624,299.7,622z M294.5,594c0,1.8-0.2,2.9-0.9,3.6c-0.7,0.7-1.6,1.1-2.9,1.1h-3.6v-25.9h3.6
  c1.4,0,2.3,0.5,2.9,1.1c0.7,0.7,0.9,1.8,0.9,3.6V594z"/>
      <polygon class="st0" points="482.8,568.6 477.2,568.6 477.2,629.6 493.1,629.6 493.1,625.3 482.8,625.3 			"/>
      <path class="st0" d="M416.8,595.4c-0.2,2.5-0.7,5-0.9,7.7c-0.2,2.7-0.5,5.2-0.7,7.7h-0.5c-0.2-2.3-0.5-5-0.7-7.7
  s-0.7-5.2-0.9-7.7l-3.4-26.8h-5.6l8.6,61h4.5l8.3-61h-5.4L416.8,595.4z"/>
      <polygon class="loading-s0" points="434.8,629.6 451.5,629.6 451.5,625.3 440.2,625.3 440.2,600.3 449.5,600.3 449.5,596.3 440.2,596.3
  440.2,572.9 451.5,572.9 451.5,568.6 434.8,568.6 			"/>
      <path class="st0" d="M549.9,599.9c-0.7-0.9-1.6-1.6-2.7-2v-0.5c0.7-0.5,1.6-1.1,2-2c0.7-1.1,0.9-2.3,0.9-3.8v-14.9
  c0-2.9-0.7-5.2-2-6.3c-1.4-1.1-3.6-1.8-6.8-1.8h-9.5v61v0.2H542c3.4,0,5.6-0.5,7-1.8c1.4-1.1,2-3.4,2-6.8v-16.4
  C551,602.6,550.6,600.8,549.9,599.9z M537.3,572.9h3.8c1.1,0,2,0.2,2.5,0.7s0.7,1.6,0.7,3.2v14.9c0,1.8-0.2,2.9-0.7,3.4
  c-0.5,0.7-1.1,0.9-2.5,0.9h-3.8V572.9z M545.2,620.6c0,2-0.2,3.2-0.7,3.8c-0.5,0.7-1.4,0.9-2.7,0.9h-4.5v-25.4v-0.2h4.5
  c1.4,0,2.3,0.2,2.7,0.9c0.5,0.7,0.7,1.8,0.7,3.6V620.6z"/>
      <path class="st0" d="M169.1,622c0-4.1-0.2-7.2-0.5-9.7c-0.2-2.5-0.5-4.5-0.7-5.9c-0.2-1.6-0.5-2.7-0.9-3.4s-0.9-1.4-1.6-2v-0.5
  c1.1-0.5,2-1.1,2.7-2.5c0.7-1.1,1.1-2.5,1.1-4.1v-16.4c0-2.9-0.9-5.2-2.3-6.8c-1.4-1.6-3.8-2.3-7-2.3h-9.2v61h5.6v-26.8h3.6
  c0.5,0,0.9,0,1.4,0.5c0.5,0.5,0.7,1.1,0.9,2c0.2,1.1,0.2,2.5,0.5,4.3c0.2,1.8,0.2,4.1,0.5,7l0.2,6.1c0.2,1.8,0.2,3.4,0.5,4.3
  c0.2,0.9,0.5,1.8,0.9,2.7h6.1c-0.7-1.1-1.1-2-1.4-3.2C169.3,625.3,169.1,624,169.1,622z M163.9,594c0,1.8-0.2,2.9-0.9,3.6
  s-1.6,1.1-2.9,1.1h-3.6v-25.9h3.6c1.4,0,2.3,0.5,2.9,1.1c0.7,0.7,0.9,1.8,0.9,3.6V594z"/>
      <path class="st0" d="M261,567.9c-3.4,0-5.9,0.9-7.2,2.7c-1.4,1.8-2,5-2,9.7v37.8c0,2.3,0,4.3,0.5,5.9c0.2,1.6,0.9,2.9,1.6,3.8
  c0.9,0.9,1.8,1.6,2.9,2c1.1,0.5,2.7,0.7,4.3,0.7c3.4,0,5.6-0.9,7.2-2.7s2.3-5.2,2.3-9.9v-12.8H265v12.8c0,1.6,0,2.7-0.2,3.8
  c-0.2,1.1-0.5,1.8-0.7,2.5c-0.2,0.7-0.7,1.1-1.1,1.4c-0.5,0.2-1.1,0.5-1.8,0.5c-0.7,0-1.4-0.2-1.8-0.5c-0.7-0.2-1.1-0.7-1.4-1.4
  c-0.2-0.7-0.5-1.6-0.7-2.5c-0.2-0.9-0.2-2.3-0.2-3.8v-37.4c0-3.2,0.2-5.2,0.9-6.3c0.7-1.1,1.6-1.6,3.2-1.6c1.4,0,2.3,0.5,2.9,1.6
  c0.7,1.1,0.9,3.2,0.9,6.3v12.8h5.4v-12.8c0-5-0.7-8.1-2.3-9.9C266.6,568.8,264.1,567.9,261,567.9z"/>
      <polygon class="st0" points="361.6,573.1 367.7,573.1 367.7,629.6 373.4,629.6 373.4,573.1 379.4,573.1 379.4,568.6 361.6,568.6
        "/>
      <polygon class="st0" points="209.6,629.6 226.3,629.6 226.3,625.3 215,625.3 215,600.3 224.3,600.3 224.3,596.3 215,596.3
  215,572.9 226.3,572.9 226.3,568.6 209.6,568.6 			"/>
      <path class="st0" d="M344.5,568.6l-8.3,61h5.4l1.6-11.9h7.4l1.4,11.9h5.6l-8.6-61H344.5z M343.9,613.2l1.8-12.6
  c0.2-2,0.5-4.3,0.7-6.5c0.2-2.3,0.2-4.3,0.5-6.1h0.5c0,1.8,0.2,3.8,0.5,6.1c0.2,2.3,0.5,4.5,0.7,6.5l1.6,12.6H343.9z"/>
      <path class="st0" d="M509.4,568.6l-8.3,61h5.4l1.6-11.9h7.4l1.4,11.9h5.6l-8.6-61H509.4z M508.7,613.2l1.8-12.6
  c0.2-2,0.5-4.3,0.7-6.5c0.2-2.3,0.2-4.3,0.5-6.1h0.5c0,1.8,0.2,3.8,0.5,6.1c0.2,2.3,0.5,4.5,0.7,6.5l1.6,12.6H508.7z"/>
      <polygon class="st0" points="123.4,573.1 129.5,573.1 129.5,629.6 135.1,629.6 135.1,573.1 141.2,573.1 141.2,568.6 123.4,568.6
        "/>
      <polygon class="st0" points="311,629.6 327.6,629.6 327.6,625.3 316.4,625.3 316.4,600.3 325.6,600.3 325.6,596.3 316.4,596.3
  316.4,572.9 327.6,572.9 327.6,568.6 311,568.6 			"/>
      <rect x="389.3" y="568.6" class="loading-s0" width="5.6" height="61"/>

      <path class="st1 tcl-stage-1" d="M198.4,618.4c0,4.5-0.7,7.7-2.3,9.2c-1.6,1.8-3.8,2.5-7.2,2.5c-1.6,0-2.9-0.2-4.1-0.7s-2-1.1-2.9-2
  c-0.7-0.9-1.4-2.3-1.6-3.6c-0.5-1.6-0.5-3.4-0.5-5.6v-49.8h5.4v49.8c0,2.9,0.2,5,0.9,6.1c0.7,1.1,1.6,1.6,2.9,1.6s2.3-0.5,2.9-1.6
  s0.9-3.2,0.9-6.1v-49.8h5.4L198.4,618.4L198.4,618.4z"/>
      <path class="st1 tcl-stage-1" d="M299.9,594c0,1.6-0.5,2.9-1.1,4.1c-0.7,1.4-1.6,2-2.7,2.5v0.5c0.7,0.7,1.1,1.4,1.6,2c0.5,0.7,0.7,1.8,0.9,3.4
  c0.2,1.4,0.5,3.4,0.7,5.9c0.2,2.5,0.5,5.6,0.5,9.7c0,2,0.2,3.4,0.5,4.5s0.7,2,1.4,3.2h-6.1c-0.5-0.9-0.7-1.8-0.9-2.7
  c-0.2-0.9-0.2-2.5-0.5-4.3l-0.2-6.1c-0.2-2.9-0.2-5.2-0.5-7c-0.2-1.8-0.2-3.2-0.5-4.3c-0.2-0.9-0.5-1.6-0.9-2
  c-0.5-0.5-0.9-0.5-1.4-0.5h-3.6v26.8h-5.6v-61h9.2c3.2,0,5.6,0.7,7,2.3c1.4,1.6,2.3,3.8,2.3,6.8L299.9,594L299.9,594z
 M287.1,572.9v25.9h3.6c1.4,0,2.3-0.5,2.9-1.1c0.7-0.7,0.9-1.8,0.9-3.6v-16.4c0-1.8-0.2-2.9-0.9-3.6s-1.6-1.1-2.9-1.1H287.1z"/>
      <path class="st1 tcl-stage-1" d="M477.2,629.6v-61h5.6v56.7h10.4v4.3H477.2z"/>
      <path class="st2 logo-stage-1" d="M119.3,116.9H5.4v37.8h113.9V116.9z"/>
      <path class="st2 logo-stage-2" d="M407.4,517L281.7,134V0l125.7,383.1V517z"/>
      <rect x="460.3" y="154.9" class="st1 logo-stage-1" width="42.8" height="243.6"/>
      <path class="st1 tcl-stage-2" d="M409.8,568.6l3.4,26.8c0.2,2.5,0.7,5,0.9,7.7c0.2,2.7,0.5,5.4,0.7,7.7h0.5c0.2-2.5,0.5-5,0.7-7.7
  c0.2-2.7,0.7-5.2,0.9-7.7l3.4-26.8h5.4l-8.3,61h-4.5l-8.6-61H409.8z"/>
      <path class="st1 tcl-stage-2" d="M434.8,629.6v-61h16.7v4.3h-11.3v23.4h9.2v4.1h-9.2v25h11.3v4.3L434.8,629.6L434.8,629.6z"/>
      <path class="st1 tcl-stage-2" d="M531.9,629.6v-61h9.5c3.2,0,5.4,0.7,6.8,1.8s2,3.4,2,6.3v14.9c0,1.6-0.2,2.7-0.9,3.8c-0.5,0.9-1.4,1.6-2,2
  v0.5c1.1,0.5,2,1.1,2.7,2c0.7,0.9,1.1,2.7,1.1,5v16.4c0,3.4-0.7,5.6-2,6.8c-1.4,1.4-3.6,1.8-7,1.8h-10.1V629.6z M537.3,572.9v23
  h3.8c1.4,0,2-0.2,2.5-0.9c0.5-0.5,0.7-1.6,0.7-3.4v-14.9c0-1.6-0.2-2.7-0.7-3.2c-0.5-0.5-1.4-0.7-2.5-0.7H537.3L537.3,572.9z
 M537.3,599.9v25.4h4.5c1.4,0,2.3-0.2,2.7-0.9c0.5-0.7,0.7-1.8,0.7-3.8v-16.4c0-1.8-0.2-2.9-0.7-3.6c-0.5-0.7-1.4-0.9-2.7-0.9
  h-4.5V599.9z"/>
      <path class="st1 logo-stage-2" d="M607.6,398.3h16.2c6.5,0,13.3-1.1,19.1-3.4c6.1-2,11.5-5.2,16-9c4.5-4.1,8.3-8.6,11-13.7
  c2.7-5.4,4.1-11.3,4.1-17.6V160.8c0-2.8-0.5-6.1-0.5-6.1s-48.4,0-49.8,0c4,0,7.2,2.2,7.2,6.1c0,3.3,0,194.1,0,194.1
  c0,1.6-0.7,2.9-1.8,4.1c-1.6,1.4-3.4,1.8-5.6,1.8h-18.7c-5.6,0-7.3-2.1-7.3-3.7l0,40.6C597.6,397.7,602.3,398.3,607.6,398.3z"/>
      <path class="st1 logo-stage-3" d="M76.6,354.7c0,1.6-0.7,2.9-1.8,4.1c-1.6,1.4-3.4,1.8-5.6,1.8H50.4c-2.3,0-4.1-0.7-5.9-1.8
  c-1.4-1.1-1.8-2.3-1.8-4.1V299H0v55.4c0,6.3,1.4,12.2,4.1,17.6c2.7,5.2,6.3,10.1,11,13.7c4.5,3.8,9.9,7,16,9
  c6.1,2,12.6,3.4,19.1,3.4h18.7c6.5,0,13.3-1.1,19.1-3.4c6.1-2,11.5-5.2,16-9c4.5-4.1,8.3-8.6,11-13.7c2.7-5.4,4.1-11.3,4.1-17.6
  l0.2-199.7H76.6V354.7z"/>
      <polygon class="st1 logo-stage-3" points="281.7,398.6 324.5,398.6 324.5,264.4 281.7,134 		"/>
      <path class="st1 tcl-stage-3" d="M169.3,594c0,1.6-0.5,2.9-1.1,4.1c-0.7,1.4-1.6,2-2.7,2.5v0.5c0.7,0.7,1.1,1.4,1.6,2s0.7,1.8,0.9,3.4
  c0.2,1.4,0.5,3.4,0.7,5.9c0.2,2.5,0.5,5.6,0.5,9.7c0,2,0.2,3.4,0.5,4.5s0.7,2,1.4,3.2h-6.1c-0.5-0.9-0.7-1.8-0.9-2.7
  c-0.2-0.9-0.2-2.5-0.5-4.3l-0.2-6.1c-0.2-2.9-0.2-5.2-0.5-7c-0.2-1.8-0.2-3.2-0.5-4.3c-0.2-0.9-0.5-1.6-0.9-2
  c-0.5-0.5-0.9-0.5-1.4-0.5h-3.6v26.8h-5.6v-61h9.2c3.2,0,5.6,0.7,7,2.3c1.4,1.6,2.3,3.8,2.3,6.8L169.3,594z M156.5,572.9v25.9h3.6
  c1.4,0,2.3-0.5,2.9-1.1c0.7-0.7,0.9-1.8,0.9-3.6v-16.4c0-1.8-0.2-2.9-0.9-3.6s-1.6-1.1-2.9-1.1H156.5z"/>
      <path class="st1 tcl-stage-3" d="M251.8,580.3c0-4.7,0.7-7.9,2-9.7c1.4-1.8,3.8-2.7,7.2-2.7c3.2,0,5.6,0.9,7.2,2.7c1.6,1.8,2.3,5,2.3,9.9v12.8
  H265v-12.8c0-3.2-0.2-5.2-0.9-6.3c-0.7-1.1-1.6-1.6-2.9-1.6c-1.6,0-2.5,0.5-3.2,1.6c-0.7,1.1-0.9,3.2-0.9,6.3v37.4
  c0,1.6,0,2.9,0.2,3.8c0.2,0.9,0.5,1.8,0.7,2.5c0.2,0.7,0.7,1.1,1.4,1.4c0.5,0.2,1.1,0.5,1.8,0.5c0.7,0,1.4-0.2,1.8-0.5
  s0.9-0.7,1.1-1.4c0.2-0.7,0.5-1.4,0.7-2.5c0.2-1.1,0.2-2.3,0.2-3.8v-12.8h5.4v12.8c0,4.7-0.7,8.1-2.3,9.9
  c-1.6,1.8-3.8,2.7-7.2,2.7c-1.6,0-3.2-0.2-4.3-0.7c-1.1-0.5-2-1.1-2.9-2c-0.7-0.9-1.4-2.3-1.6-3.8c-0.5-1.6-0.5-3.6-0.5-5.9V580.3
  z"/>
      <path class="st1 tcl-stage-3" d="M367.7,629.6v-56.5h-6.1v-4.5h17.8v4.5h-6.1v56.5H367.7z"/>
      <path class="st2 logo-stage-3" d="M597.4,170.2v-9.7c0-1.6,0.7-2.9,1.8-4.1c1.4-1.4,3.4-1.8,5.6-1.8h7.9h11h49.8c-0.7-4.1-1.8-7.7-3.6-11.3
  c-2.7-5.2-6.3-10.1-11-14c-4.5-4.1-9.9-7.2-16-9.2c-6.1-2-12.6-3.4-19.1-3.4h-18.7c-6.5,0-13.3,1.1-19.1,3.4
  c-6.1,2-11.5,5.2-16,9.2c-4.5,4.1-8.3,8.8-11,14c-2.7,5.4-4.1,11-4.1,17.1v194.1c0,6.3,1.4,12.2,4.1,17.6
  c2.7,5.2,6.3,10.1,11,13.7c4.5,3.8,9.9,7,16,9c3.6,1.4,7.7,2.3,11.7,2.7L597.4,170.2z"/>
      <path class="st1 tcl-stage-4" d="M209.6,629.6v-61h16.7v4.3H215v23.4h9.2v4.1H215v25h11.3v4.3L209.6,629.6L209.6,629.6z"/>
      <path class="st1 tcl-stage-4" d="M350.6,617.7h-7.4l-1.6,11.9h-5.4l8.3-61h4.5l8.6,61H352L350.6,617.7z M348.4,600.6c-0.2-2-0.5-4.3-0.7-6.5
  c-0.2-2.3-0.5-4.3-0.5-6.1h-0.5c-0.2,1.8-0.2,3.8-0.5,6.1c-0.2,2.3-0.5,4.5-0.7,6.5l-1.8,12.6h6.1L348.4,600.6z"/>
      <path class="st1 tcl-stage-4" d="M515.4,617.7H508l-1.6,11.9H501l8.3-61h4.5l8.6,61h-5.6L515.4,617.7z M513.2,600.6c-0.2-2-0.5-4.3-0.7-6.5
  c-0.2-2.3-0.5-4.3-0.5-6.1h-0.5c-0.2,1.8-0.2,3.8-0.5,6.1c-0.2,2.3-0.5,4.5-0.7,6.5l-1.8,12.6h6.1L513.2,600.6z"/>
      <path class="st2 logo-stage-4" d="M424.9,154.9h35.4h42.8h35.8v-38.1H424.9L424.9,154.9L424.9,154.9z"/>
      <path class="st1 logo-stage-4" d="M193.6,398.3h16.2c6.5,0,13.3-1.1,19.1-3.4c6.1-2,11.5-5.2,16-9c4.5-4.1,8.3-8.6,11-13.7
  c2.7-5.4,4.1-11.3,4.1-17.6V160.8c0-2.8-0.5-6.1-0.5-6.1s-48.4,0-49.8,0c4,0,7.2,2.2,7.2,6.1c0,3.3,0,194.1,0,194.1
  c0,1.6-0.7,2.9-1.8,4.1c-1.6,1.4-3.4,1.8-5.6,1.8h-18.7c-5.6,0-7.3-2.1-7.3-3.7l0,40.6C183.6,397.7,188.3,398.3,193.6,398.3z"/>
      <path class="st1 tcl-stage-5" d="M129.5,629.6v-56.5h-6.1v-4.5h17.8v4.5h-6.1v56.5H129.5z"/>
      <path class="st1 tcl-stage-5" d="M311,629.6v-61h16.7v4.3h-11.3v23.4h9.2v4.1h-9.2v25h11.3v4.3L311,629.6L311,629.6z"/>
      <path class="st1 tcl-stage-5" d="M389.3,629.6v-61h5.6v61H389.3z"/>
      <path class="st2 logo-stage-5" d="M183.4,170.2v-9.7c0-1.6,0.7-2.9,1.8-4.1c1.4-1.4,3.4-1.8,5.6-1.8h7.9h11h49.8c-0.7-4.1-1.8-7.7-3.6-11.3
  c-2.7-5.2-6.3-10.1-11-14c-4.5-4.1-9.9-7.2-16-9.2c-6.1-2-12.6-3.4-19.1-3.4h-18.7c-6.5,0-13.3,1.1-19.1,3.4
  c-6.1,2-11.5,5.2-16,9.2c-4.5,4.1-8.3,8.8-11,14c-2.7,5.4-4.1,11-4.1,17.1v194.1c0,6.3,1.4,12.2,4.1,17.6
  c2.7,5.2,6.3,10.1,11,13.7c4.5,3.8,9.9,7,16,9c3.6,1.4,7.7,2.3,11.7,2.7L183.4,170.2z"/>
      <polygon class="st1 logo-stage-5" points="364.6,252.6 364.6,116.9 407.4,116.9 407.4,383.1 		"/>
</svg>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "Loading",
  props: ['percent'],
  data: () => ({
    stages: {
      tcl: [],
      logo: []
    }
  }),
  watch: {
    $route(to){
      if (to.name === 'About'){
        for (let i = 0; i < 5; i++){
          if (i === 4){
            gsap.to(this.stages.tcl[i], {duration: 0.5, ease: 'linear', fill: '#BFBEBE', onComplete: () => {
                this.$emit('loading', false)
              }}).delay(i / 5)
          }
          else {
            gsap.to(this.stages.tcl[i], {duration: 0.5, ease: 'linear', fill: '#BFBEBE'}).delay(i / 5)
          }
          this.stages.logo[i].forEach( e => {
            if (e.classList[0] === 'st1'){
              gsap.to(e, {duration: 0.5, ease: 'linear', fill: '#BFBEBE'}).delay(i / 5)
            }
            else {
              gsap.to(e, {duration: 0.5, ease: 'linear', fill: '#FFFFFF'}).delay(i/ 5)
            }
          })
        }
      }
    },
    percent(){
      //TCL
      if (this.percent >= 5){
        gsap.to(this.stages.tcl[this.percent - 1], {duration: 0.5, ease: 'linear', fill: '#BFBEBE', onComplete: () => {
            this.$emit('loading', false)
          }}).delay(this.percent / 5)
      }
      else {
        gsap.to(this.stages.tcl[this.percent - 1], {duration: 0.5, ease: 'linear', fill: '#BFBEBE'}).delay(this.percent / 5)

      }
      //Logo
      if (this.stages.logo[this.percent - 1]){
        this.stages.logo[this.percent - 1].forEach( e => {
          if (e.classList[0] === 'st1'){
            gsap.to(e, {duration: 0.5, ease: 'linear', fill: '#BFBEBE'}).delay(this.percent / 5)
          }
          else {
            gsap.to(e, {duration: 0.5, ease: 'linear', fill: '#FFFFFF'}).delay(this.percent / 5)
          }
        })
      }
    }
  },
  async mounted() {
    const tcl_1 = await document.querySelectorAll('.tcl-stage-1')
    const tcl_2 = await document.querySelectorAll('.tcl-stage-2')
    const tcl_3 = await document.querySelectorAll('.tcl-stage-3')
    const tcl_4 = await document.querySelectorAll('.tcl-stage-4')
    const tcl_5 = await document.querySelectorAll('.tcl-stage-5')

    const logo_1 = await document.querySelectorAll('.logo-stage-1')
    const logo_2 = await document.querySelectorAll('.logo-stage-2')
    const logo_3 = await document.querySelectorAll('.logo-stage-3')
    const logo_4 = await document.querySelectorAll('.logo-stage-4')
    const logo_5 = await document.querySelectorAll('.logo-stage-5')

    this.stages.tcl.push(tcl_1, tcl_2, tcl_3, tcl_4, tcl_5)
    this.stages.logo.push(logo_1, logo_2, logo_3, logo_4, logo_5)
  }
}
</script>

<style lang="sass">
.loading-container
  position: absolute
  height: 100%
  width: 100%
  z-index: 10
  display: flex
  flex-direction: column-reverse
  align-items: center
  .loading-svg
    position: absolute
    left: 50%
    top: 49%
    transform: translate(-50%, -50%)
    width: 26vh
    z-index: 1
    @media (min-width: 768px)
      width: 34vh
    .st0
      fill: #2F2F2F
    .st1
      fill: #2F2F2F
    .st2
      fill: #2F2F2F
</style>