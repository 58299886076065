<template>
  <div class="navigation">
    <nav>
      <ul>
        <li @click="setShowMenu">
          <router-link
              tag="a"
              to="/"
              exact
              class="link"
              :class="{'link-active': isParamsId}"
              active-class="link-active"
          >
            {{ menu.portfolio }}
          </router-link>
        </li>
        <li @click="setShowMenu">
          <router-link
              tag="a"
              to="/about"
              class="link"
              active-class="link-active"
          >
            {{ menu.about }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: ['menu'],
  data: () => ({
    isParamsId: false
  }),
  methods: {
    setShowMenu(){
      this.$emit('showMenu', false)
    }
  },
  watch: {
    $route(path){
      if (path.params.id) {
        this.isParamsId = true
      }
    }
  }
}
</script>

<style lang="sass">
$threshold: calc(20px + 15 * ((100vw - 320px) / (1920 - 320)))
$height2: calc(2 * (68px + (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320))))))
$height: calc(68px + (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320)))))
.navigation
  position: absolute
  left: 0
  top: 0
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center

  overflow: auto
  z-index: 1

  background-color: #000
  @media (max-height: 425px) and (orientation: landscape)
    height: 425px
  nav
    width: inherit
    display: flex
    justify-content: flex-end
    align-items: center

    ul
      margin: 0
      padding-right: $threshold
      list-style: none
      text-align: right
      li
        font-size: calc(95px + 50 * ((100vw - 320px) / (1920 - 320)))
        font-family: 'Galderglynn1884SqRg', sans-serif
        font-weight: 400
        line-height: calc(102px + 72 * ((100vw - 320px) / (1920 - 320)))
        letter-spacing: 0.02em
        text-transform: uppercase

        .link
          color: #ffffff40
          transition: 0.5s ease
          &:hover
            color: #fff
        .link-active
          color: #fff
</style>