import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: '/projects/:id',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: { title: `JONTO • True Creative Lab` }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: { title: `JONTO • True Creative Lab` }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About'),
    meta: { title: 'О нас • JONTO • True Creative Lab' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
