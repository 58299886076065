import firebase from "firebase/app";
import "firebase/database"

export default {
  actions: {
    async fetchLanguages() {
      try {
        return (await firebase.database().ref('languages').once('value')).val() || []
      } catch (e) {
        console.log(e)
        throw e;
      }
    }
  }
}