<template>
  <footer
      :class="{'footer-m': showMenu}"
  >
    <div
        class="scene-cube"
        id="btn"
        v-show="currentPage === 'Home' && !showMenu">
      <div class="cube">
        <div 
          :class="{'cube__face-front-active': perspective}"
          class="cube__face cube__face-front"></div>
        <div 
          :class="{'cube__face-back-active': perspective}"
          class="cube__face cube__face-back"></div>
        <div 
          :class="{'cube__face-right-active': perspective}"
          class="cube__face cube__face-right"></div>
        <div 
          :class="{'cube__face-left-active': perspective}"
          class="cube__face cube__face-left"></div>
        <div 
          :class="{'cube__face-top-active': perspective}"
          class="cube__face cube__face-top"></div>
        <div 
          :class="{'cube__face-bottom-active': perspective}"
          class="cube__face cube__face-bottom"></div>
      </div>
    </div>
    
    <!-- #3D Cube -->
    <div
        class="contacts fromTo"
        v-show="!projectBtnLink || showMenu">
      <div class="mail">
        <a
          href="mailto:jonto@gmail.com"
          :style="{ color: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000000' : '#FFFFFF' }"
      >
        jonto@gmail.com
        </a>
      </div>
      <div class="social-networks">
        <a href="#" class="social-networks__block">
          <svg class="ico facebook"
               width="30" height="30" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 141.7 141.7" style="enable-background:new 0 0 141.7 141.7;" xml:space="preserve">
              <path class="st0"
                    d="M2.4,70.9c0-38.1,30.9-68.5,68.5-68.5c38.1,0,68.5,30.9,68.5,68.5c0,38.1-30.9,68.5-68.5,68.5
                    C32.8,139.3,2.4,109,2.4,70.9z"/>
              <path class="st1"
                    :style="{ fill: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#BFBEBE' : '#939393' }"
                    d="M70.9,141.7c-39,0-70.9-31.8-70.9-70.9S31.8,0,70.9,0s70.9,31.8,70.9,70.9S109.9,141.7,70.9,141.7z M70.9,4.8
      c-36.6,0-66,29.4-66,66s29.9,66,66,66s66-29.9,66-66C136.9,34.2,107.5,4.8,70.9,4.8z"/>
              <path class="st2"
                    :style="{ fill: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000000' : '#FFFFFF' }"
                    d="M54,73.3h9.2v31.8h12.5V73.3h12.5l0.5-11.6h-13c0-4.8,0-7.2,0-7.7c0,0,0,0,0-0.5c0-2.9,1-5.3,4.8-5.3
      s7.2,0,7.2,0l0.5-10.6c0,0-3.4-0.5-8.7-0.5c-11.6,0-16.4,7.2-16.4,14.5c0,5.3,0,9.6,0,9.6H54V73.3z"/>
          </svg>
        </a>
        <a href="#" class="social-networks__block">
          <svg class="ico twitter" width="30" height="30" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 141.7 141.7" style="enable-background:new 0 0 141.7 141.7;" xml:space="preserve">
            <path class="st0" d="M2.4,70.9c0-38.1,30.9-68.5,68.5-68.5c38.1,0,68.5,30.9,68.5,68.5c0,38.1-30.9,68.5-68.5,68.5
      C32.8,139.3,2.4,109,2.4,70.9z"/>
            <path class="st1"
                  :style="{ fill: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#BFBEBE' : '#939393' }"
                  d="M70.9,141.7c-39,0-70.9-31.8-70.9-70.9S31.8,0,70.9,0s70.9,31.8,70.9,70.9S109.9,141.7,70.9,141.7z M70.9,4.8
      c-36.6,0-66,29.4-66,66s29.9,66,66,66s66-29.9,66-66C136.9,34.2,107.5,4.8,70.9,4.8z"/>
            <path class="st2"
                  :style="{ fill: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000000' : '#FFFFFF' }"
                  d="M106.2,53.2c-2.2,1.2-4.9,1.9-7.5,1.9c2.7-1.5,4.9-4.1,5.6-7.2c-2.7,1.5-5.3,2.7-8.3,3
      c-2.3-2.2-5.6-3.8-9.5-3.8c-7.2,0-13.3,5.6-13.3,12.8c0,1.2,0,1.9,0.4,3c-10.8-0.7-20.3-5.6-26.7-13.6c-1.2,1.9-1.9,4.2-1.9,6.4
      c0,4.6,2.2,8.3,5.6,10.6c-2.2,0-4.1-0.7-6-1.5l0,0c0,6,4.6,11.3,10.6,12.4c-0.7,0.4-1.9,0.7-3,0.7c-0.7,0-1.5,0-2.7-0.4
      c1.5,4.9,6.4,8.7,12.1,8.7c-4.6,3.4-10.2,5.3-16.2,5.3c-1.2,0-2.2,0-3,0c5.6,3.8,12.8,5.6,20,5.6c24.2,0,37.3-19.2,37.3-36.3v-1.5
      C102.3,57.8,104.6,55.9,106.2,53.2L106.2,53.2z"/>
          </svg>
        </a>
        <a href="#" class="social-networks__block">
          <svg class="ico vk" width="30" height="30" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 141.7 141.7" style="enable-background:new 0 0 141.7 141.7;" xml:space="preserve">
            <path class="st0" d="M2.4,70.9c0-38.1,30.9-68.5,68.5-68.5c38.1,0,68.5,30.9,68.5,68.5c0,38.1-30.9,68.5-68.5,68.5
      C32.8,139.3,2.4,109,2.4,70.9z"/>
            <path class="st1"
                  :style="{ fill: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#BFBEBE' : '#939393' }"
                  d="M70.9,141.7c-39,0-70.9-31.8-70.9-70.9S31.8,0,70.9,0s70.9,31.8,70.9,70.9S109.9,141.7,70.9,141.7z M70.9,4.8
      c-36.6,0-66,29.4-66,66s29.9,66,66,66s66-29.9,66-66C136.9,34.2,107.5,4.8,70.9,4.8z"/>
            <path class="st2"
                  :style="{ fill: (currentPage === 'About' && !showMenu) || (clicked && !showMenu) ? '#000000' : '#FFFFFF' }"
                  d="M91.6,72.5c-1.9-2-3.3-3.3-6.6-3.9v-0.4c2.4-1.1,4.1-2.5,5.4-4.2c1.3-1.8,1.9-3.9,1.9-6.3
      c0-2-0.4-3.9-1.4-5.4c-1-1.6-2.4-2.9-4.3-3.9c-1.8-0.9-3.7-1.5-5.6-1.8c-1.8-0.3-6-0.4-10.1-0.4H51.5v49.2h22c3.7,0,6.7-0.4,9-1
      c2.4-0.6,4.6-1.7,6.6-3.1c1.7-1.2,3.1-2.8,4-4.6c1-1.8,1.5-3.9,1.5-6.4C94.5,77.1,93.5,74.5,91.6,72.5z M76.8,84.8
      c-1,0.5-2.4,0.8-4.1,0.8h-7.5V74h7.7c1.8,0,3.1,0.3,4.1,0.7c1.8,0.9,2.6,2.5,2.6,4.7C79.5,82.1,78.6,84,76.8,84.8z M76.1,64.6
      c-1.1,0.7-2.5,1.1-4.3,1.1h-6.7V56h5.6c1.9,0,3.6,0.3,4.8,0.7c1.5,0.6,2.2,2,2.2,4C77.8,62.7,77.2,63.9,76.1,64.6z"/>
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: ['showMenu', 'clicked', 'perspective', 'projectBtnLink'],
  computed: {
    currentPage() {
      return this.$route.name;
    }
  }
}
</script>

<style lang="sass">
$threshold: calc(20px + 15 * ((100vw - 320px) / (1920 - 320)))
$thresholdTopBottom: calc(14px + 10 * ((100vw - 320px) / (1920 - 320)))
footer
  pointer-events: none
  position: fixed
  bottom: 0
  right: 0
  width: 100%
  display: flex
  padding: $thresholdTopBottom $threshold

  z-index: 2
  .contacts
    display: flex
    margin-left: auto
  //Mail
  .mail
    pointer-events: visible
    margin-right: 25px
    text-align: center
    display: flex
    align-items: center
    padding-top: 3.5px
    font-family: 'BebasNeueRegular', sans-serif
    font-size: 18px
    font-weight: 400
    letter-spacing: 0.02em
  //Social networks
  .social-networks
    display: flex
    justify-content: space-between
    align-items: center
    width: calc(31px * 3 + 10px * 2)
    .social-networks__block
      pointer-events: visible
      display: flex
      justify-content: center
      align-items: center
      .ico
        &:hover > .st0
          fill: #c4c4c420
        .st0
          fill: transparent
        .st1
          fill: #939393
        .st2
          fill: #FFFFFF

.scene-cube
  pointer-events: visible
  position: relative
  width: 30px
  height: 30px
  perspective: 45px
  cursor: pointer
  display: none
  @media (min-width: 768px)
    display: block

.cube
  width: 30px
  height: 30px
  position: relative
  transform-style: preserve-3d
  transform: translateZ(-15px)

.cube__face
  position: absolute
  width: 30px
  height: 30px
  border: 1px solid #FFFFFF
  transition: 1.2s ease

.cube__face-front  
  transform: rotateY(0deg) translateZ(15px)
  &-active
    transform: rotateY(30deg) translateZ(15px)
.cube__face-right  
  transform: rotateY(90deg) translateZ(15px)
  &-active
    transform: rotateY(120deg) translateZ(15px)
.cube__face-back   
  transform: rotateY(180deg) translateZ(15px)
  &-active
    transform: rotateY(210deg) translateZ(15px)
.cube__face-left   
  transform: rotateY(-90deg) translateZ(15px)
  &-active
    transform: rotateY(-60deg) translateZ(15px)
.cube__face-top    
  transform: rotateX(90deg) translateZ(15px)
  &-active
    transform: rotateX(90deg) rotateZ(-30deg) translateZ(15px)
.cube__face-bottom 
  transform: rotateX(-90deg) translateZ(14px)
  &-active
    transform: rotateX(-90deg) rotateZ(30deg) translateZ(15px)

//Media
@media (max-height: 425px) and (orientation: landscape)
  .footer-m
    position: absolute
    bottom: unset
    top: 365px
</style>