<template>
  <div
      id="app"
      :style="{ background: currentPage === 'About' && !showMenu ? '#ffffff' : '#000000' }"
  >
    <transition name="hf">
      <Header
        @showMenu="getShowMenu"
        v-model="showMenu"
        v-show="!loading"
        :clicked="clicked"
    ></Header>
    </transition>
    <!--Page-->
    <main>
      <keep-alive>
        <router-view
            @clicked="getClicked"
            @perspective="getPerspective"
            @projectBtnLink="getProjectBtnLink"
            :showMenu="showMenu"
            @percent="getPercent"
            :hideLoading="hideLoading"
        ></router-view>
      </keep-alive>
    </main>
    <!--#Page-->
    <transition name="fade">
      <Navigation
          @showMenu="getShowMenu"
          v-show="showMenu"
          :menu="menu"
      ></Navigation>
    </transition>
    <transition name="hf">
      <Footer
        v-show="!loading"
        :showMenu="showMenu"
        :clicked="clicked"
        :perspective="perspective"
        :projectBtnLink="projectBtnLink"
    ></Footer>
    </transition>
    <transition name="fade">
      <Loading v-if="!hideLoading" :percent="percent" @loading="getLoading"/>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Loading from "./components/Loading";
import gsap from 'gsap'

export default {
  data: () => ({
    loading: true,
    aboutLoaded: false,
    hideLoading: false,
    percent: false,
    showCanvasBlocks: false,
    showMenu: false,
    clicked: false,
    perspective: false,
    fromTo: null,
    projectBtnLink: '',
    menu: {
      about: '',
      portfolio: ''
    }
  }),
  components: {
    Header, Footer, Navigation, Loading
  },
  computed: {
    currentPage() {
      return this.$route.name;
    }
  },
  watch: {
    clicked(){
      if (this.$route.name !== 'About'){
        if (this.clicked){
          gsap.timeline().fromTo(this.fromTo, { x: 280 }, {
            duration: 0.2,
            x: 0
          }, 0)
        }
        else {
          gsap.timeline().to(this.fromTo, {duration: 0.2, x: 280, onComplete: () => {
              gsap.to(this.fromTo, {duration: 0, x: 0})
            }}, 0)
        }
      }
    },
    $route(path){
      if (path.name === 'About'){
        this.clicked = false
        this.$children.forEach(e => {
          if (e.$el.className === 'portfolio'){
            e.clicked = false
            e._data.slider.state.isClicked = false
            e._data.slider.group.children.forEach(e => {
              e.material.uniforms.opacity.value = 1;
              e.material.uniforms.u_strength.value = 0;
            })
          }
        })
      }
    }
  },
  methods: {
    randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    getShowMenu(e){
      this.showMenu = e
    },
    getClicked(e){
      this.clicked = e
    },
    getPerspective(e){
      this.perspective = e
    },
    getProjectBtnLink(e){
      this.projectBtnLink = e
    },
    getPercent(e){
      this.percent = Math.ceil(e)
    },
    getLoading(e){
      if (this.$route.name === 'Home'){
        this.$children.forEach(e => {
          if (e.slider){
            e.slider.group.children.forEach(e =>{
              if (!e.u_position){
                gsap.to(e.material.uniforms.opacity, {
                  delay: this.randomNumber(0.5, 2),
                  duration: 0.25,
                  ease: 'linear',
                  value: 1
                })
              }
              else{
                gsap.to(e.material.uniforms.opacity, {
                  delay: 2.5,
                  ease: 'linear',
                  duration: 0.25,
                  value: 1,
                  onStart: () => {
                    setTimeout(() => {
                      this.hideLoading = true
                    }, 2.5)
                  }
                })
              }
            })
          }
        })
      }
      else {
        this.hideLoading = true
      }
      this.loading = e
    }
  },
  async mounted(){
    const languages = await this.$store.dispatch('fetchLanguages')
    let LANGUAGE

    let lLanguages = []

    let tempArr = []

    Object.keys(languages).forEach(key => {
      lLanguages.push(languages[key])
      if (languages[key].visible) {
        tempArr.push({
          title: languages[key].name,
          flag: languages[key].images.flag,
          id: key,
          pos: languages[key].pos
        })
      }
    })

    lLanguages.sort((a, b) => a.pos - b.pos)

    try {
      const localLanguage = JSON.parse(localStorage.getItem('language'))

      if (localLanguage && languages[localLanguage.id] && languages[localLanguage.id].visible) {
        LANGUAGE = localLanguage
      }
      else {
        // eslint-disable-next-line no-unused-vars
        for (let lan of lLanguages) {
          if (lan.visible) {
            LANGUAGE = {id: lan.id, name: lan.name}
            break
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-unused-vars
      for (const [_, value] of Object.entries(languages)) {
        if (value.visible){
          LANGUAGE = {id: value.id, name: value.name}
          break
        }
      }
    }

    const currLanguage = languages[LANGUAGE.id]

    this.menu = currLanguage.menu

    this.fromTo = document.querySelectorAll('.fromTo')
    //Shit chrome in ios
    const userAgent = navigator.userAgent.toLowerCase();
    const Chrome = /crios/.test(userAgent);
    if (Chrome){
      const body = document.querySelector('body')
      body.style.position = 'absolute'
    }
  }
}
</script>

<style lang="sass">
@font-face
  font-family: "OpenSansCondensedLight"
  src: url("./assets/fonts/OpenSansCondensedLight/OpenSansCondensed-Light.woff") format("woff"),
  url("./assets/fonts/OpenSansCondensedLight/OpenSansCondensed-Light.woff2") format("woff2")
  font-weight: normal
  font-style: normal
@font-face
  font-family: "BebasNeueRegular"
  src: url("./assets/fonts/BebasNeueRegular/BebasNeueRegular.woff") format("woff"),
  url("./assets/fonts/BebasNeueRegular/BebasNeueRegular.woff2") format("woff2")
  font-weight: normal
  font-style: normal
@font-face
  font-family: "Galderglynn1884SqDb"
  src: url("./assets/fonts/Galderglynn1884SqDb-Regular/Galderglynn1884SqDb-Regular.woff") format("woff"),
  url("./assets/fonts/Galderglynn1884SqDb-Regular/Galderglynn1884SqDb-Regular.woff2") format("woff2")
  font-weight: normal
  font-style: normal
@font-face
  font-family: "Galderglynn1884SqLt"
  src: url("./assets/fonts/Galderglynn1884SqLt-Regular/Galderglynn1884SqLt-Regular.woff") format("woff"),
  url("./assets/fonts/Galderglynn1884SqLt-Regular/Galderglynn1884SqLt-Regular.woff2") format("woff2")
  font-weight: normal
  font-style: normal
@font-face
  font-family: "Galderglynn1884SqRg"
  src: url("./assets/fonts/Galderglynn1884SqRg-Regular/Galderglynn1884SqRg-Regular.woff") format("woff"),
  url("./assets/fonts/Galderglynn1884SqRg-Regular/Galderglynn1884SqRg-Regular.woff2") format("woff2")
  font-weight: normal
  font-style: normal
@font-face
  font-family: "Galderglynn1884SqRg-Bold"
  src: url("./assets/fonts/Galderglynn1884SqRg-Bold/Galderglynn1884SqRg-Bold.woff") format("woff"),
  url("./assets/fonts/Galderglynn1884SqRg-Bold/Galderglynn1884SqRg-Bold.woff2") format("woff2")
  font-weight: normal
  font-style: normal
*
  margin: 0
  padding: 0
  box-sizing: border-box
  -webkit-tap-highlight-color: transparent
html, body
  overflow: hidden
  width: 100%
  height: 100%
  -webkit-text-size-adjust: 100%
body
  font-family: 'OpenSansCondensedLight', sans-serif
  font-size: 16px
  position: fixed
  color: #fff
  background-color: #000
h1
  margin: 0
  padding: 0
a
  text-decoration: none
  &:active
    color: unset
img
  height: 100%
  width: 100%
main
  height: 100%
  width: 100%
  overflow-x: hidden
  overflow-y: auto
#app
  display: flex
  position: relative
  width: 100%
  height: 100%
  overflow-y: auto
//Fade
.fade-enter, .fade-leave-to,
.hf-enter, .hf-leave-to,
.project-fade-enter, .project-fade-leave-to
  opacity: 0

.fade-enter-active, .fade-leave-active
  transition: all 0.55s ease

//Hf
.hf-enter-active, .hf-leave-active
  transition: 2s ease

//Project-fade
.project-fade-enter-active, .project-fade-leave-active
  transition: 1s ease
  transition-delay: 0.5s

//Global css
//Shadow
.shadow
  width: 100%
  height: 100%
  transition: 1.2s ease
  pointer-events: none
.shadow-img-t
  pointer-events: none
  position: fixed
  top: 0
  width: 100%
.shadow-img-b
  pointer-events: none
  position: fixed
  bottom: 0
  width: 100%
  transform: rotate(180deg)
.shadow-img
  height: 200px
.t-black-shadow
  position: absolute
  top: 0
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 44%)
.b-black-shadow
  position: absolute
  bottom: 0
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 56%, #000000 100%)
//Scroll
*::-webkit-scrollbar
  display: none
  max-width: 6px
*::-webkit-scrollbar-track
  background: #f0f0f0
/* color of the tracking area */
*::-webkit-scrollbar-thumb
  background-color: #000   /* color of the scroll thumb */
  border-radius: 10px       /* roundness of the scroll thumb */
  border: 2.5px solid #f0f0f0   /* creates padding around scroll thumb */
</style>
